import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"

import { aemAxiosInstance } from "@/constants/api"
import {
  GLOBAL_CONFIGURATION_ENDPOINT,
  COMPONENT_TYPES,
  PDP_TEASER_DETAILS,
} from "@/constants/index"
import { recursiveDataFunction } from "@/utils/index"
import { store } from "@/store/index"
import { setIsTeaserLoaded, setTeaserContent } from "@/store/features/pdpSlice"
import { addBuyBtnAnalytics } from "@/components/ProductDetail/v3/analytics"

const fetchSkuFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const urlSku1 = urlParams.get("skuId")
  const urlSku = urlSku1 ? urlSku1 : this.getskuFromParams(urlParams)
  this.setState({
    selectedSku: urlSku ? urlSku : this.state.selectedSku,
  })
}
export const getInitialSku = data => {
  const { productData, setSkuVariantCb, selectedColor } = data
  let colors = []
  if (productData["Color.SKU.Details_ss"]) {
    colors = productData["Color.SKU.Details_ss"].map(color => {
      const d = color.split("|")
      return {
        color: d.at(0),
        sku: d[2],
        discontinued: !(d[4] === "false" || Date.parse(d[4]) > Date.now()),
      }
    })
  } else if (productData["Color.SKU.Details_s"]) {
    const d = productData["Color.SKU.Details_s"].split("|")
    colors = [
      {
        color: d.at(0),
        sku: d[2],
        discontinued: !(d[4] === "false" || Date.parse(d[4]) > Date.now()),
      },
    ]
  } else if (productData["SKU.Details_ss"]) {
    colors = productData["SKU.Details_ss"].map(color => {
      const d = color.split("|")
      return {
        sku: d[0],
        discontinued: !(d[2] === "false" || Date.parse(d[2]) > Date.now()),
      }
    })
  }
  const availableSkus = colors.filter(color => !color.discontinued)
  const discontinuedAvailableSkus = colors.filter(color => color.discontinued)

  let initSku = ""
  if (availableSkus[0]?.sku) {
    const { sku: selectedColorSku = "" } =
      availableSkus.find(el => el.color === selectedColor) ?? {}
    initSku = selectedColorSku
      ? selectedColorSku
      : availableSkus[0]?.sku ?? productData["masterSKU_s"]
  } else {
    initSku =
      discontinuedAvailableSkus[0]?.sku ?? productData["variantList.sku_ss"]
        ? productData["variantList.sku_ss"][0]
        : productData["variantList.sku_s"]
  }

  setSkuVariantCb && setSkuVariantCb(initSku)

  return initSku
}

export const checkProductCategory = (productCategory, category) => {
  if (productCategory === category || productCategory === "") {
    return true
  }
  return false
}

export const getZipCodeJsonFile = (jsonFileUrl, getZipCodeJsonFileCb) => {
  try {
    if (!_isEmpty(jsonFileUrl)) {
      aemAxiosInstance({
        url: GLOBAL_CONFIGURATION_ENDPOINT,
        params: {
          path: jsonFileUrl,
        },
      }).then(response => {
        if (response && response.data) {
          getZipCodeJsonFileCb && getZipCodeJsonFileCb(response.data)
        } else {
          console.error(
            "API endpoint Url: Error occured while fetching data from Get URL Property"
          )
        }
      })
    } else {
      console.warn("API endpoint Url: Get URL Property not found")
    }
  } catch (error) {
    console.error(error)
  }
}

export const getShippingLocations = (statesData, states) => {
  let resultTxt = ""
  if (statesData && states?.length > 0) {
    states.map((state, i) => {
      const stateObj = statesData.find(
        st => state === st.value?.replace(/:US/g, "")
      )
      if (stateObj?.text) {
        resultTxt = resultTxt + stateObj.text
        if (i === states.length - 2) resultTxt += " and "
        else if (i !== states.length - 1) resultTxt += ", "
      }
    })
  }
  return resultTxt
}

export const getRequireItems = (
  items,
  discountedPrice,
  price,
  setInstallReqCb,
  setSubTotalCb,
  setPriceListCb,
  addItemsToCartCb
) => {
  setInstallReqCb(items)
  const cartItemProduct = []
  const priceListItems = []
  let requiredItemsPrice = 0
  let priceLists = 0
  let baseTotal = 0
  let subTotalItem = 0
  if (items.length > 0) {
    items &&
      items.map(list => {
        const product = {}
        product.sku = list.skuid
        product.brand = list.brandName
        if (list.backorder) {
          product.quantity = 1
          product.backorder = true
          cartItemProduct.push(product)
        } else {
          const checkQuantity = _get(
            list.colorsData.find(
              requiredItem => requiredItem.id === list.skuid
            ),
            "isOutOfStock",
            false
          )
          product.quantity = !checkQuantity ? 1 : 0
          if (!checkQuantity) {
            cartItemProduct.push(product)
          }
        }
        if (list?.priceData) {
          priceLists =
            _get(
              list.priceData.find(
                requiredList => requiredList.id === list.skuid
              ),
              "discountedPrice",
              0
            ) > 0
              ? _get(
                  list.priceData.find(
                    requiredList => requiredList.id === list.skuid
                  ),
                  "discountedPrice",
                  0
                )
              : _get(
                  list.priceData.find(
                    requiredList => requiredList.id === list.skuid
                  ),
                  "price",
                  0
                )

          priceListItems.push(priceLists)
          if (priceListItems.length >= 0) {
            requiredItemsPrice = priceListItems.reduce((acc, item) => {
              return parseFloat(acc) + parseFloat(item)
            }, 0)
          } else {
            requiredItemsPrice = priceListItems[0]
          }
          baseTotal = discountedPrice > 0 ? discountedPrice : price
          subTotalItem = baseTotal + requiredItemsPrice
          setSubTotalCb(subTotalItem)
          setPriceListCb(requiredItemsPrice)
        }
      })
    addItemsToCartCb(cartItemProduct, false)
  } else {
    addItemsToCartCb([], false)
  }
}

export const getTeaserFragments = (pageData, pdpData) => {
  const teaserFragmentOrder = getFragmentsOrder(pageData)
  const customerFacingSKU = pdpData["masterSKU_s"] ?? ""
  const category = pdpData["ProductLocalCategory_s"] ?? ""
  const pdpXFPaths = getFragmentPaths(pageData)
  if (teaserFragmentOrder && teaserFragmentOrder.length) {
    store.dispatch(setIsTeaserLoaded(true))
    const params = {
      skuId: customerFacingSKU,
      category: category,
    }
    if (pdpXFPaths) params.xfPaths = pdpXFPaths
    aemAxiosInstance({
      url: PDP_TEASER_DETAILS,
      params,
    })
      .then(response => {
        const { data } = response
        if (data && teaserFragmentOrder.length)
          getTeaserDetails(data, teaserFragmentOrder)
        else console.error("PDP Teaser Fragments Order Not Found..!")
      })
      .catch(error => {
        console.error("PDP Teaser Fragments Fetching Error..!")
      })
  }
}

const getFragmentsOrder = pageData => {
  const itemOrder = []
  const teaserFragments = recursiveDataFunction(
    pageData,
    COMPONENT_TYPES.PRODUCT_DETAILS_EXFPDP
  )
  if (teaserFragments && teaserFragments.length) {
    const list = _get(teaserFragments[0], "configuredXfPathModels", "")
    list.map(item => {
      const itemArray = item && item?.path && item.path.split("/")
      itemOrder.push(itemArray[itemArray.length - 1])
    })
    return itemOrder
  }
}

const getFragmentPaths = pageData => {
  const teaserFragments = recursiveDataFunction(
    pageData,
    COMPONENT_TYPES.PRODUCT_DETAILS_EXFPDP
  )
  if (teaserFragments && teaserFragments.length) {
    const fragmentPaths = teaserFragments.reduce((paths, fragment) => {
      const list = _get(fragment, "configuredXfPathModels", "")
      list.forEach(item => {
        paths.push(item?.path)
      })
      return paths
    }, [])
    return fragmentPaths.join(",")
  }

  return ""
}

export const getTeaserDetails = (data, teaserFragmentOrder) => {
  const teaserDataPromises = []
  teaserFragmentOrder.forEach(item => {
    if (data[item]) {
      teaserDataPromises.push(
        new Promise((resolve, reject) => {
          aemAxiosInstance({
            url: GLOBAL_CONFIGURATION_ENDPOINT,
            params: {
              path: `${data[item]}.model.json`,
            },
          })
            .then(res => {
              resolve(res.data)
            })
            .catch(error => {
              reject(error)
            })
        })
      )
    }
  })
  Promise.all(teaserDataPromises)
    .then(response => {
      store.dispatch(setTeaserContent(response))
    })
    .catch(error => {
      store.dispatch(setTeaserContent([]))
      console.error("PDP Teaser Fragments Data Fetching Error..!")
    })
}

export const initPriceSpider = () => {
  const waitTimeForPsWidgetElementsToLoad = 500
  let psWidgetNode = document.getElementsByClassName("ps-widget")[0]

  // Here reason to use mutation observer instead of react ref is bcoz
  // this ps widget elements are getting fluctuated
  const psWidgetObserver = new MutationObserver(() => {
    // fired when a mutation occurs
    psWidgetNode.querySelectorAll("div[data-retailer]").forEach(retailerDiv => {
      const btn = retailerDiv.querySelector(".ps-online-buy-button")
      const retailer = retailerDiv.getAttribute("data-retailer")?.toLowerCase()
      if (btn.getAttribute("attachedEvent") !== "true") {
        btn.addEventListener(
          "click",
          e => {
            addBuyBtnAnalytics(e, retailer)
          },
          false
        )
        btn.setAttribute("attachedEvent", "true")
      }
    })
  })

  let psWidgetObserverTimeout
  const addObserverIfDesiredNodeAvailable = () => {
    psWidgetNode = document.getElementsByClassName("ps-widget")[0]
    if (!psWidgetNode) {
      psWidgetObserverTimeout = setTimeout(
        addObserverIfDesiredNodeAvailable,
        waitTimeForPsWidgetElementsToLoad
      )
      return
    }

    if (psWidgetObserverTimeout) {
      clearTimeout(psWidgetObserverTimeout)
      psWidgetObserverTimeout = undefined
    }

    const config = { subtree: true, childList: true }
    psWidgetObserver.observe(psWidgetNode, config)
  }

  addObserverIfDesiredNodeAvailable()
}

export const getDataSheetUrl = (productServices = [], currentSku = "") => {
  const currentSkuMasterVariant =
    productServices.find(
      product => product?.masterVariant?.sku === currentSku
    ) ?? {}

  const { masterVariant: { attributes = [] } = {} } = currentSkuMasterVariant

  const { value: productResourceData = [] } =
    attributes.find(e => e.name === "ProductResource") ?? {}

  const dataSheet =
    productResourceData.find(e => {
      return e.find(el => el.value === "DataSheet")
    }) ?? []

  const { value: resourceFullWebURL = "" } =
    dataSheet.find(e => e.name === "ResourceFullWebURL") ?? {}

  return resourceFullWebURL
}

export const getColorDataObject = d => {
  return {
    name: d[0],
    swatch: d[1],
    sku: d[2],
    isStock: d[3],
    discontinued: !(d[4] === "false" || Date.parse(d[4]) > Date.now()),
    customerSku: d[5],
  }
}

export const getColorFinishData = (data = {}) => {
  return data["Color.SKU.Details_ss"]
}

export const checkUserZipCode = data => {
  const { userZipCode, state, callBack } = data
  let availableSkuIdsForService = []
  const { zipCodeData = [] } = state
  for (const zipKey of zipCodeData) {
    const key = Object.keys(zipKey)[0]
    for (const zipInnerKey of zipKey[key]) {
      const zipcode = Object.keys(zipInnerKey)[0]
      if (zipcode === userZipCode) {
        availableSkuIdsForService = zipInnerKey[zipcode]
        break
      }
    }
    if (availableSkuIdsForService.length) break
  }
  const isNearest = availableSkuIdsForService.length
  callBack && callBack(isNearest)
}
