import { ADD_10_MINUTES, GLOBAL_CONFIGURATION_ENDPOINT } from "."
import { aemAxiosInstance } from "@/constants/api"

/**
 *
 * @return {{initConfig, getConfig}}
 */
function configuration() {
  let _configurationObj = {}

  const initConfig = async () => {
    let urlPath = window.location.pathname
    urlPath =  urlPath === '/' ? "/en" : urlPath
    const path = `${GLOBAL_CONFIGURATION_ENDPOINT}?path=${urlPath}&caConfig=true`
    _configurationObj = JSON.parse(
      localStorage &&
        localStorage.getItem(`caConfig-${document.documentElement.lang}`)
    ) ?? {
      expireTime: 0,
    }
    const currentTime = new Date().getTime()
    if (path && currentTime > _configurationObj.expireTime) {
      await aemAxiosInstance.get(path).then(response => {
        if (response && response.data) {
          const _backendConfig = response.data

          if (_backendConfig?.general?.isPublish) {
            const getShortenedUrl = uUrl => {
              const shortUrl = _backendConfig?.general?.shortendPagePath ?? ""
              const shortUrlRegEx = new RegExp(shortUrl, "g")
              const htmlRegExp = new RegExp(".html", "g")
              return uUrl.replace(shortUrlRegEx, "").replace(htmlRegExp, "")
            }

            const pattern = `^(?!.*json)${_backendConfig.general.shortendPagePath}.*`

            for (const link in _backendConfig.general) {
              if ({}.hasOwnProperty.call(_backendConfig.general, link)) {
                const url = `${_backendConfig.general[link]}`
                if (url.match(pattern)) {
                  if (link !== "shortendPagePath")
                    _backendConfig.general[link] = getShortenedUrl(
                      _backendConfig.general[link]
                    )
                }
              }
            }
          }
          _configurationObj = Object.assign(_configurationObj, _backendConfig)
          _configurationObj.annsacksPDPConfig =
            response.data?.annsacksPDPConfig?.map
          _configurationObj.apiEndpoints =
            response.data?.apiEndPointData?.map ?? {}
          const assetAccountName =
            response.data?.general.scene7AccountName ?? "PAWEB"
          _configurationObj.general.swatchUrl =
            _configurationObj.general.swatchUrl.replace(
              "PAWEB",
              assetAccountName
            )
        } else {
          console.error("Error occurred while fetching Backend Configuration")
        }
      })
      await aemAxiosInstance
        .get(
          GLOBAL_CONFIGURATION_ENDPOINT +
            "?path=" +
            _configurationObj.general?.plpServletPath
        )
        .then(res => {
          _configurationObj.plpServletPath = res.data?.pdpURLMapping
          _configurationObj.expireTime = new Date().getTime() + ADD_10_MINUTES
          if (localStorage)
            localStorage.setItem(
              `caConfig-${document.documentElement.lang}`,
              JSON.stringify(_configurationObj)
            )
        })
        .catch(err =>
          console.error("Error occurred while fetching PLP Servlet path", err)
        )
    } else {
      console.log("Configuration URL for found")
    }
  }

  const getConfig = async () => {
    let configTimer
    const config = new Promise(resolve => {
      if (_configurationObj?.expireTime === 0) {
        configTimer = setTimeout(() => resolve(getConfig()), 500)
      } else {
        configTimer && clearTimeout(configTimer)
        resolve(_configurationObj)
      }
    })
    return config
  }
  return {
    initConfig,
    getConfig,
  }
}

export const { initConfig, getConfig } = configuration()
