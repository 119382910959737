import * as globalConstants from "../src/constants"

export const findAStoreUrl = "/locations/find-a-store.model.json"
globalConstants.COMPONENT_TYPES.BUTTON = "button"
export const GLOBAL_CONFIGURATION_PATH =
  "/bin/kohler/services/globalconfig.annsacks.json"
export const DEFAULT_CARD_DETAILS_API_PATH =
  "/bin/kohler/services/list?path=/content/annsacks"
export const GENERAL_ERROR_MESSAGE = "Something went wrong. Please try again..."

export * from "../src/constants"
