import { createSlice } from "@reduxjs/toolkit"

 
const initialState = {
  asProductDetails:{
    sku: '',
    slug: '',
    variants: [],
    recommendedAccessories: [],
    productBrand: '',
    data: {}
},
}

export const asPdpSlice = createSlice({
  name: "asPdp",
  initialState,
  reducers: {
    setAsProductDetails(state, action) {
    if(action && action?.payload !== null && typeof action?.payload === 'object' && !Array.isArray(action?.payload)){
      state.asProductDetails={
        ...state?.asProductDetails,
        ...action?.payload
      }
    }
  }},
  extraReducers: () => {},
})
 
export const { setAsProductDetails } = asPdpSlice.actions
export const selectPdpState = state => state.asPdp
 
export default asPdpSlice.reducer