import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { apim } from "@/constants/api"
import { getConfig } from "@/constants/config"
import { getUserPersona } from "@/utils/helper"
import qs from "qs"

const initialState = {
  inspirations: {
    selectedInspiration: {},
    loading: true,
    loaded: false,
    fq: [],
    data: [],
    total: 0,
    facets: [],
    start: 0,
    colors: {},
    grouped: false,
  },
}

export const getInspirationDetails = createAsyncThunk(
  "inspirations/getInspirationDetails",
  async ({
    query = {},
    update = false,
    isSingle = false,
    search = "",
    cb = null,
  }) => {
    const config = await getConfig()
    const lwAppName = config.general?.lwAppName ?? ""
    const language = config?.internationalization?.language ?? "en"
    const persona = getUserPersona()

    query?.fq.push(`assetType_s:("Inspiration Gallery")`)
    query?.fq.push(`language_s:("${language}")`)

    try {
      const fl = [
        "id",
        "url_s",
        "ProductMappingSKUID_s",
        `priceStartingAt.${persona}_d`,
        "productDetailsList_ss",
        "title_s",
        "thumbnailImageURL_s",
        "resourceCredit_s",
        "customSampleProductName_s",
        "customsampleimagepath_s",
        "badge_s",
        "description_s",
        "productImagesList_ss",
      ]
      const { data } = await apim.get(`/search/plp`, {
        params: {
          fl: fl.join(","),
          q: search ? `*${search}*` : "*:*",
          ...query,
          rows: query.rows || 30,
          start: query.start || 0,
          profilename: `profile_${lwAppName.toLowerCase()}_PLP_Inspiration_Gallery`,
          collections: lwAppName.toLowerCase(),
        },
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: "repeat", encode: false })
        },
      })
      const payload = {
        facets: data.facet_counts?.facet_fields ?? {},
        facetLabels: data.fusion?.facet_labels ?? [],
        loading: false,
      }

      if (data.response) {
        payload.data = data.response.docs
        payload.total = data.response.numFound
        payload.start = data.response.start
        if (
          data.fusion?.isCollapsePLP === "true" ||
          data.fusion?.isCollapsePLP === true
        ) {
          payload.grouped = true
        }
      } else {
        payload.data = data.grouped[Object.keys(data.grouped)[0]].groups.map(
          doc => doc.doclist.docs[0]
        )
        payload.total = data.grouped[Object.keys(data.grouped)[0]].groups.length
        payload.start = 0
        payload.grouped = true
      }

      if (isSingle) {
        return { type: "GET_INSPIRATION", payload }
      } else {
        if (update) {
          return { type: "GET_MORE_INSPIRATIONS", payload }
        } else {
          return { type: "GET_INSPIRATIONS", payload }
        }
      }
    } catch (err) {
      if (cb) {
        cb({
          title: "automatic search",
          status: "failure",
          eventMsg: err,
          searchProps: {
            isSearch: true,
            searchResult: 0,
            searchCount: sessionStorage.getItem("inspiration_searches")
              ? Number(sessionStorage.getItem("inspiration_searches")) + 1
              : 1,
            searchTerm: search.toLowerCase(),
          },
        })
      }
      throw err
    }
  }
)

export const inspirationSlice = createSlice({
  name: "inspirations",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getInspirationDetails.pending, (state, action) => {
        return {
          ...state,
          inspirations: {
            ...state.inspirations,
            loading: true,
            loaded: false,
          },
        }
      })
      .addCase(getInspirationDetails.fulfilled, (state, action) => {
        if (action.payload.type === "GET_INSPIRATION") {
          return {
            ...state,
            inspirations: {
              selectedInspiration: {
                ...action.payload.payload,
              },
            },
          }
        } else if (action.payload.type === "GET_MORE_INSPIRATIONS") {
          return {
            ...state,
            inspirations: {
              ...state.inspirations,
              data: [
                ...state.inspirations.data,
                ...action.payload.payload.data,
              ],
              loading: false,
              loaded: true,
            },
          }
        } else if (action.payload.type === "GET_INSPIRATIONS") {
          return {
            ...state,
            inspirations: {
              ...state.inspirations,
              ...action.payload.payload,
              loading: false,
              loaded: true,
            },
          }
        }
      })
      .addCase(getInspirationDetails.rejected, (state, action) => {
        return {
          ...state,
          inspirations: {
            ...state.inspirations,
            loading: false,
            loaded: true,
          },
        }
      })
  },
})

export const { startLoading, stopLoading } = inspirationSlice.actions
export const selectInspirationState = state => state.inspirations

export default inspirationSlice.reducer
